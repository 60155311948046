import { initI18n } from '@bluecodecom/i18n';

export const RESOURCES = {
  de: {
    translation: {},
  },
  en: {
    translation: {},
  },
} as const;

initI18n(RESOURCES, {
  interpolation: {
    escapeValue: false,
  },
});
